<template>
    <div class="ad_article_main">
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
        <div v-for="(adInfo, adIndex) in adInfos" v-bind:key="adIndex">
            <div style="padding-left: 16px; padding-bottom: 10px; padding-top: 10px"><h3><b><font color="blue">{{ adInfos[adIndex].assignedName }}</font></b></h3></div>
            <div style="padding-bottom: 50px">
                <vs-table :data="adInfos[adIndex].info">
                    <template slot="thead">
                    <vs-th><h4><b>포지션</b></h4></vs-th>
                    <vs-th><h4><b>상태</b></h4></vs-th>
                    </template>
                    <template slot-scope="{data}">
                        <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">

                            <vs-td :data="tr.posName">
                                <div @click="showPopup(tr, adIndex)"><b style="color: #ff7f00">{{ tr.posName }}</b></div>
                            </vs-td>

                            <vs-td v-if="!tr.posUsed" v-model="tr.posUsed">
                            <b style="color: red">미사용</b>
                            </vs-td>
                            <vs-td v-if="tr.posUsed" v-model="tr.posUsed">
                            <b style="color: #D3D3D3">사용중</b>
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>

        </div>

            <!-- S: form of comp of ad -->
            <div class="write-new-ad-form">
                <vs-popup classContent="popup-example" title="광고사 선택" :active.sync="form.actives.comp">
                    
                    <div v-for="(item, idx) in adCompInfo" v-bind:key="idx">
                        <div @click="selectAdComp(item); form.actives.comp = false" style="padding: 10px; cursor: pointer"><b>{{ item.text }}</b></div>
                    </div>
                </vs-popup>
            </div>
            <!-- E: form of editing ad -->

            <!-- S: form of writing ad -->
            <div class="write-new-ad-form">
                <vs-popup classContent="popup-example" title="광고 작성" :active.sync="form.actives.write">

                    <div style="display: flex">
                        <vs-button v-if="isAdminLevel(userInfo.level)" color="dark" type="line" @click="openFormSelectAdComp()">광고사 선택</vs-button>
                        <div v-if="isAdminLevel(userInfo.level)" style="padding: 10px 10px 0px 20px">광고사: </div><div style="padding: 10px 0px 0px 0px"><b>{{ form.vars.comp.name }}</b></div>
                        <div style="padding: 10px 10px 0px 20px">순서: </div><vs-input-number v-model="form.vars.write.order"/>
                    </div>
                    <vs-textarea style="display:flex" v-model="form.vars.write.source" rows="10" />
                    <div class="vx-col sm:w-2/3 w-full ml-auto">
                        <vs-button class="mr-3 mb-2" @click="tryWrite()">작성</vs-button>
                        <vs-button class="mb-2" color="warning" type="border" @click="form.actives.write=false">취소</vs-button>
                    </div>
                </vs-popup>
            </div>
            <!-- E: form of writing ad -->

            <!-- S: form of editing ad -->
            <div class="write-new-ad-form">
                <vs-popup classContent="popup-example" title="광고 수정" :active.sync="form.actives.edit">
                    
                    <div style="display: flex">
                        <div style="padding: 10px 10px 0px 20px">순서: </div><vs-input-number v-model="form.vars.edit.order"/>
                    </div>
                    <vs-textarea style="display:flex" v-model="form.vars.edit.source" rows="10" />
                    <div class="vx-col sm:w-2/3 w-full ml-auto">
                        <vs-button class="mr-3 mb-2" @click="tryEdit()">수정</vs-button>
                        <vs-button class="mb-2" color="warning" type="border" @click="form.actives.edit=false">취소</vs-button>
                    </div>
                </vs-popup>
            </div>
            <!-- E: form of editing ad -->

            <!-- Start: 광고리스트 팝업 -->
            <div class="write-new-ad-form">
                <vs-popup classContent="popup-example" title="등록된 광고" :active.sync="adModalActive">

                    <vs-table :data="selectedAds">
                        <template slot="thead">
                            <vs-th>순서</vs-th>
                            <vs-th>회사명</vs-th>
                            <vs-th>작성</vs-th>
                            <vs-th>코드</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr v-bind:key="indextr2" v-for="(tr2, indextr2) in data">
                                <vs-td>
                                    <b>{{ tr2.banner_order }}</b>
                                </vs-td>
                                <vs-td>
                                    <b>{{ tr2.compName }}</b>
                                </vs-td>
                                <vs-td>
                                    <b>{{ tr2.lastWritten }}</b>
                                </vs-td>
                                <vs-td>
                                    <div class="con-expand-users w-full">
                                        <div class="con-btns-user items-center justify-between">
                                            <div class="con-userx items-center justify-start">
                                                <div style="posName:relative">
                                                    <div style="padding-left: 10px; padding-bottom: 30px; display: inline; float: left">
                                                        <vs-button v-if="isAdminLevel(userInfo.level) || userInfo.idx == tr2.compId" @click="openFormEditAd(tr2)" type="border" size="small" icon-pack="feather" icon="icon-edit" class="mr-2"></vs-button>
                                                        <vs-button v-if="isAdminLevel(userInfo.level) || userInfo.idx == tr2.compId" @click="tryDelete(selectedPos, tr2)" type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button>
                                                    </div>
                                                    <div class="flex" style="padding-left: 10px">
                                                        <vs-textarea disabled v-model="tr2.source" rows="5"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>

                    <div style="padding: 20px">
                        <vs-button @click="openFormWriteNewAd(adInfos[selectedAdIndex].assignedId, selectedPos)" type="border" size="small" icon-pack="feather" icon="icon-edit" color="dark"></vs-button>
                    </div>
                </vs-popup>
            </div>
            <!-- End: 광고리스트 팝업 -->
    </div>
</template>

<script>
export default {
  data() {
    return {
        adModalActive: false,

        selectedAds: [],

        areaId: 1,
        
        adCompInfo: {},

        userInfo: {
            idx: 0,
            level: 0
        },

        form: {
            vars: {
                write: {
                    assignedId: 0,
                    posId: 0,
                    source: '',
                    order: 10,
                },
                edit: {
                    bannerId: 0,
                    source: '',
                    order: 10,
                },
                comp: {
                    id: 0
                }
            },
            
            actives: {
                write: false,
                edit: false,
                comp: false,
            },
        },
        adInfos: []
    }
  },
  watch:{
      '$route': function(){
          this.created();
      }
  },
  created() {
      this.created();
  },
  methods: {
    created() {
        if(this.$route.path.match('mains')){
            this.areaId = 1;
        }else if(this.$route.path.match('sides')){
            this.areaId = 2;
        }else if(this.$route.path.match('header')){
            this.areaId = 3;
        }else if(this.$route.path.match('footer')){
            this.areaId = 4;
        }
        var thisObj = this

        thisObj.loadUserData(function() {

            if(thisObj.isAdminLevel(thisObj.userInfo.level)) {

                thisObj.loadAdComps()
            }

            thisObj.loadAds()
        })
    },
    showPopup(data, adIndex){
        let banner = data.banner || []
        // console.log(data);
        // if(banner && banner.length){
            this.selectedAdIndex = adIndex;
            this.selectedPos = data;
            this.selectedAds = banner;
            this.adModalActive = true;
        // }
    },
    loadUserData(callback) {

        var thisObj = this

        thisObj.userInfo.idx = this.$store.state.AppActiveUser.id
        thisObj.userInfo.level = this.$store.state.AppActiveUser.level

        callback()
    },
    loadAdComps() {
        var thisObj = this

        this.$http.post('/api/adComp/list/selectable', {})
        .then(function (response) {

            thisObj.adCompInfo = []

            // console.log("response.data:", response.data)
            for(var idx in response.data) {

                thisObj.adCompInfo.push({value: response.data[idx].id, text: response.data[idx].name})
            }
            
            // console.log('thisObj.adCompInfo:', thisObj.adCompInfo)
        })
    },
    loadAds() {

        var thisObj = this

        this.$http.post('/api/adBanner/load', {areaId: this.areaId})
        .then(function (response) {

            // console.log(thisObj.adInfos)
            console.log('response.data:', response.data)

            thisObj.adInfos = response.data
        })
        .catch(function (error) {
            thisObj.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
            thisObj.$vs.loading.close();  
        });
    },

    isAdminLevel(level) {

        return level >= 9
    },

    tryWrite() {

        var thisObj = this

        var queryData = {}
        
        if(this.isAdminLevel(this.userInfo.level)) {

            if(!this.form.vars.comp.id) {
                
                thisObj.$vs.notify({
                    title:'미입력',
                    text: '광고사를 선택해주세요',
                    color:'warning',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})

                return
            }
            else {
                
                queryData.compId = this.form.vars.comp.id
            }
        }
        else {

            queryData.compId = this.userInfo.idx
        }

        queryData.areaId = this.areaId
        queryData.assignedId = this.form.vars.write.assignedId
        queryData.posId = this.form.vars.write.posId
        queryData.source = this.form.vars.write.source
        queryData.order = this.form.vars.write.order

        console.log("queryData:", queryData)

        this.form.actives.write = false
        this.resetWriteForm()
        this.resetCompForm()

        this.$http.post('/api/adBanner/write', queryData)
        .then(function (response) {

            console.log('response.data:', response.data)
            if(response.data.status == 'success') {

                thisObj.adInfos = response.data.banners
                thisObj.adModalActive = false;
                // for(let infoIdx in response.data.banners){
                //     for(let bannerIdx in response.data.banners[infoIdx].info){
                //         thisObj.adInfos[infoIdx].info[bannerIdx].banner = response.data.banners[infoIdx].info[bannerIdx].banner
                //     }
                // }

                thisObj.$vs.notify({
                    title:'광고 작성 완료',
                    color:'success',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
            }
            else {

                thisObj.$vs.notify({
                    title:'알 수 없는 문제가 발생하여 광고 작성에 실패했습니다',
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
            }
        })
        .catch(function (error) {
            thisObj.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
            thisObj.$vs.loading.close();  
        });
    },
    tryEdit() {

        var thisObj = this

        var queryData = {}

        queryData.areaId = this.areaId
        queryData.bannerId = this.form.vars.edit.bannerId
        queryData.source = this.form.vars.edit.source
        queryData.order = this.form.vars.edit.order

        console.log("queryData:", queryData)

        this.form.actives.edit = false
        this.resetEditForm()
        this.resetCompForm()

        this.$http.post('/api/adBanner/edit', queryData)
        .then(function (response) {

            console.log('response.data:', response.data)
            if(response.data.status == 'success') {

                thisObj.adInfos = response.data.banners
                thisObj.adModalActive = false;
                // for(let infoIdx in response.data.banners){
                //     for(let bannerIdx in response.data.banners[infoIdx].info){
                //         thisObj.adInfos[infoIdx].info[bannerIdx].banner = response.data.banners[infoIdx].info[bannerIdx].banner
                //     }
                // }

                thisObj.$vs.notify({
                    title:'광고 수정 완료',
                    color:'primary',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
            }
            else {

                thisObj.$vs.notify({
                    title:'알 수 없는 문제가 발생하여 광고 작성에 실패했습니다',
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
            }
        })
        .catch(function (error) {
            thisObj.$vs.notify({
            title:'Error',
            text: error,
            color:'danger',
            iconPack: 'feather',
            icon:'icon-alert-circle'})
            thisObj.$vs.loading.close();  
        });
    },
    tryDelete(posData, data) {

        var thisObj = this

        var queryData = {}

        queryData.areaId = this.areaId
        queryData.posId = posData.posId
        queryData.bannerId = data.bannerId

        console.log(queryData)

        thisObj.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `광고 삭제`,
            text: `삭제한 광고 소스는 복구가 불가능합니다. '${posData.posName}' 소스를 삭제하시겠습니까?`,
            accept: function() {

                this.$http.post('/api/adBanner/delete', queryData)
                .then(function (response) {

                    console.log('response.data:', response.data)
                    if(response.data.status == 'success') {

                        thisObj.adInfos = response.data.banners
                        thisObj.adModalActive = false;
                        // for(let infoIdx in response.data.banners){
                        //     for(let bannerIdx in response.data.banners[infoIdx].info){
                        //         thisObj.adInfos[infoIdx].info[bannerIdx].banner = response.data.banners[infoIdx].info[bannerIdx].banner
                        //     }
                        // }

                        thisObj.$vs.notify({
                            title:'광고 삭제 완료',
                            text: `${data.compName} '${posData.posName}'`,
                            color:'warning',
                            iconPack: 'feather',
                            icon:'icon-alert-circle'})
                    }
                    else {

                        thisObj.$vs.notify({
                            title:'알 수 없는 문제가 발생하여 광고 작성에 실패했습니다',
                            color:'danger',
                            iconPack: 'feather',
                            icon:'icon-alert-circle'})
                    }
                })
                .catch(function (error) {
                    thisObj.$vs.notify({
                    title:'Error',
                    text: error,
                    color:'danger',
                    iconPack: 'feather',
                    icon:'icon-alert-circle'})
                    thisObj.$vs.loading.close();  
                });
            }
        })
    },

    openFormSelectAdComp() {

        this.form.actives.comp = true
    },
    openFormWriteNewAd(assignedId, data) {

        this.form.vars.write.assignedId = assignedId
        this.form.vars.write.posId = data.posId
        this.form.actives.write = true
    },
    openFormEditAd(data) {

        console.log('data:', data)

        this.form.vars.edit.bannerId = data.bannerId
        this.form.vars.edit.source = data.source
        this.form.vars.edit.order = data.banner_order
        this.form.actives.edit = true
    },

    selectAdComp(obj) {

        this.form.vars.comp.id = obj.value
        this.form.vars.comp.name = obj.text
    },

    resetWriteForm() {
        
        this.form.vars.write.posId = 0
        this.form.vars.write.source = ''
    },
    resetEditForm() {
        
        this.form.vars.edit.bannerId = 0
        this.form.vars.edit.source = ''
    },
    resetCompForm() {

        this.form.vars.comp.id = 0
        this.form.vars.comp.name = ''
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  }
}
</script>